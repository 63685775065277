"use client";
import classes from "../styles/Analysis.module.css";

export default function DropDownMode(props) {
  function onChange (event) {
    console.log(event.target.value);
    props.setGraphMode (event.target.value);
  }
  console.log(props);
  return (
    <div className={classes.containerContent}>
      <label>Line Type</label>
      <select id="barney" onChange={onChange} value={this}>
      {/* <option value="">-</option> */}
        {props.graphType.map((el) => (
          <option value={el} key={el}>{el}</option>
        ))}
      </select>
    </div>
  );
}
