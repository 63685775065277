// "use client";
import Plot from 'react-plotly.js';
import React from 'react';
import classes from "../styles/Analysis.module.css"


export default function LineGraph(props) { 

  console.log (props);
  let xArray=[];
  let yArray=[];

  props.data.map ((el) => xArray.push(el[2].substr(1,2) + "." + el[2].substr(4,2)+ "." + el[2].substr(9,2)+ " " + el[2].substr(12,2) + ":" + el[2].substr (15,2)   ));
  props.data.map ((el) => yArray.push(parseFloat(el[4])));

  //console.log (xArray);
  //console.log (yArray);

  return (
    <div className={classes.containerContent}>
    <Plot className={classes.containerContentGraph}
        data={[
          {
            // x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ,13, 14],
            x: xArray,
            y: yArray,
            type: 'bubble', //bar, line, scatter, pie, bubble
            mode: props.graphMode,
            //mode: 'lines+markers',
            marker: {color: 'red'},
          },
          // {type: 'bar', x: [1, 2, 3, 4, 5, 6, 7, 8, 9,10], y: [2, 5, 3]},
          // {type: 'bar', x: [1, 2, 3, 4, 5, 6, 7, 8, 9,10], y: [2, 5, 3]},
        ]}
        layout={ {width: 1600, height: 480, title: "TAG: "+ props.title} }
      />
      </div>
  );
}
