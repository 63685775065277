export default function MinValue(props) {
    console.log(props);
    let minValue = 9999999999;
    console.log(minValue);

    props.data.forEach ((el) => {if (parseFloat(el[4]) < parseFloat(minValue) ) {
        minValue = parseFloat(el[4]);
        //console.log(parseFloat(el[4]));
    }})

    return (
        <div>Min Value: {minValue}</div>
    );
  }