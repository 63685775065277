export default function AvgValue(props) {
    //console.log(props);
    let cnt = 0
    let totalValue = 0;

    props.data.forEach ((el) => {
        cnt++;
        totalValue = parseFloat(totalValue) + parseFloat(el[4]);
    })

    //console.log (cnt);
    //console.log (totalValue);
    
    let avgValue = totalValue/cnt;
    //console.log ()
    return (
        <div>Avg Value: {avgValue}</div>
    );
  }