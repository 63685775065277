"use client";
import classes from "../styles/Analysis.module.css";
export default function DataGraph(props) {
  return (
    <div className={classes.containerContentData}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableRow}>
            {props.headers?.map((header, i) => (
              <th key={i} className={classes.tableHeader}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.filterRows?.map((rowData, i) => {
            return (
              <tr key={i} className={classes.tableRow}>
                {rowData?.map((data, i) => {
                  return (
                    <td key={i} className={classes.tableData}>
                      {data}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
