import React, { useState } from "react";
import classes from "./styles/Analysis.module.css";
// import CSVReader from "@/components/CSVReader";
import Dropdown from "./pages/DropDown";
import DropdownMode from "./pages/DropDownMode";
import onlyUnique from "./pages/UniqueValues";
import CSVSelector from "./pages/CSVSelector";
import MaxValue from "./pages/MaxValue";
import MinValue from "./pages/MinValue";
import AvgValue from "./pages/AvgValue";
import LineGraph from "./pages/LineGraph";
import DataGraph from "./pages/DataGraph";
import TopMenu from "./pages/TopMenu";

function App() {
  const [data, setData] = useState([]);
  const [dataTag, setDataTag] = useState([]);
  const [graphMode, setGraphMode] = useState([]);
  const headers = data[0];
  const rows = data.slice(1);

  const graphType = [ "lines","lines+markers",  "markers"];

  const localArray = [""];
  let tag = "";
  data.forEach((el) => {
    tag = el[0];
    localArray.push(tag);
  });

  localArray.splice(0, 2); // Remove the first two elements
  var unique = localArray.filter(onlyUnique);
  let filterRows = [];
  rows.forEach((el) => {
    if (el[0] === dataTag) {
      filterRows.push(el);
    }
  });
  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <TopMenu />
      </div>

      <div className={classes.container}>
        <div className={classes.container}>
          <CSVSelector onChange={(_data) => setData(_data)} />
        </div>

        <div className={classes.container}>
          <Dropdown data={unique} setDataTag={setDataTag} />
        </div>

        <div className={classes.container}>
          <DropdownMode graphType={graphType} setGraphMode={setGraphMode} />
        </div>

        <div className={classes.container}>
          <div className={classes.containerContent}>
            <div className={classes.containerData}>
              <div>Elements Loaded: {filterRows.length}</div>
              <MaxValue data={filterRows} />
              <MinValue data={filterRows} />
              <AvgValue data={filterRows} />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <LineGraph data={filterRows} title={dataTag} graphMode={graphMode}/>
      </div>
      <div className={classes.container}>
        <DataGraph filterRows={filterRows} headers={headers} />
      </div>
    </div>
  );
}

export default App;
