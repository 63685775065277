export default function MaxValue(props) {
    console.log(props);
    let maxValue = 0;
    console.log(maxValue);

    props.data.forEach ((el) => {if (parseFloat(el[4]) > parseFloat(maxValue) ) {
        maxValue = parseFloat(el[4]);
        //console.log(parseFloat(el[4]));
    }})

    return (
        <div>Max Value: {maxValue}</div>
    );
  }