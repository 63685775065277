"use client";
import classes from "../styles/Analysis.module.css";

export default function DropDown(props) {
  function onChange (event) {
    console.log(event.target.value);
    props.setDataTag (event.target.value);
  }
  console.log(props);
 
  const tags = [];
  props.data.map((el) => tags.push(parseInt(el)));
  //console.log (tags)
  tags.sort((a,b) => a - b);
  //console.log (tags)

  return (
    <div className={classes.containerContent}>
      <label>Tag</label>
      <select id="fred" onChange={onChange} value={this}>
      <option value="">-</option>
        {tags.map((el) => (
          <option value={el} key={el}>{el}</option>
        ))}
      </select>
    </div>
  );
}
